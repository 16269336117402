<template>
  <button
    :class="btnClass"
    :disabled="disabled || loading"
    :title="title"
    :type="buttonType"
    @click.capture="handleClick"
    v-if="showButton"
  >
    <cs-loading v-if="loading" :theme="loadingTheme"> <slot name="loadingSecondary"/></cs-loading>
    <span v-if="iconLeft && !loading" :icon="iconLeft" class="mx-1">
      <cs-icon :icon="iconLeft" />
    </span>
      <span v-if="icon && !loading" class="mx-1">
      <cs-icon :icon="icon" />
    </span>
    <slot name="default" />

    <span v-if="iconRight && !loadingSecondary" class="mx-1">
      <cs-icon :icon="iconRight" />
    </span>

    <cs-loading v-if="loadingSecondary" :theme="loadingTheme">
      <slot name="loadingSecondary" />
    </cs-loading>
  </button>
</template>

<script>
import CsLoading from '@/components/controls/CsLoading'
export default {
  name: 'CsButton',
  components: {
    CsLoading: CsLoading
  },
  props: {
    buttonType: {
      type: String,
      required: false,
      default: 'button'
    },
    type: {
      type: [String, Object, Array],
      default: 'secondary'
    },
    size: {
      type: [String, Object, Array],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isOutline: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: [Boolean, String, Object],
      default: false,
      required: false
    },
    iconLeft: {
      type: [Boolean, String, Object],
      default: false,
      required: false
    },
    iconRight: {
      type: [Boolean, String, Object],
      default: false,
      required: false
    },
    showSpinner: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    loadingSecondary: {
      type: Boolean,
      default: false
    },
    truncateText: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    userRole: {
      type: String,
      default: null
    },
    minRole: {
      type: String,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    loadingTheme () {
      switch (this.type) {
        case 'warning':
        case 'light':
          return 'dark'
        default:
          return 'light'
      }
    },
    btnClass () {
      const btnClass = ['btn']
      if (this.type != null) {
        if (typeof this.type === 'string' && this.type !== '') {
          const items = 'btn-' + (this.isOutline ? 'outline-' : '') + this.type
          btnClass.push(items)
        }
      }
      if (this.size != null && this.size !== '') {
        if (typeof this.size === 'string') {
          const items = 'btn-' + (this.isOutline ? 'outline-' : '') + this.size
          btnClass.push(items)
        }
      }
      if (this.truncateText) {
        btnClass.push('btn-truncate-text')
      }
      if (typeof this.customClass === 'string') {
        btnClass.push(this.customClass)
      }
      if (this.disabled) {
        btnClass.push('disabled')
      }
      if (this.active) {
        btnClass.push('active')
      }
      return btnClass
    },
    ghost () {
      return this.finalDisabled || this.loading || this.loadingSecondary
    },
    showButton () {
      if (this.minRole === null) {
        return true
      }
      switch (this.minRole) {
        case 'admin':
          return ['admin'].includes(this.userRole)
        case 'manager':
          return ['admin', 'program'].includes(this.userRole)
        case 'site':
          return ['admin', 'program', 'site'].includes(this.userRole)
        case 'staff':
          return ['admin', 'program', 'site', 'staff'].includes(this.userRole)
        case 'parent':
          return ['admin', 'program', 'site', 'staff', 'parent'].includes(
            this.userRole
          )
      }
      return false
    }
  },
  methods: {
    handleClick (event) {
      if (!this.disabled && !this.loading) {
        if (this.ghost) {
          event.preventDefault()
          event.stopPropagation()
          event.stopImmediatePropagation()
        } else {
          this.$emit('handle-click', event)
          this.$emit('click', event)
        }
      }
    }
  }
}
</script>

<style scoped></style>
